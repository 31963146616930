<template>
  <section class="destination-must-see-entity" data-aos="fade-up">
    <div class="destination-must-see-entity__section destination-must-see-entity__section--content">
      <div>
        <div class="destination-must-see-entity__content">
          <header class="destination-must-see-entity__header">
            <h3 class="destination-must-see-entity__title">
              <DestinationMustSeeEntityPageLink :entityRoute="entityRoute">
                {{ entity.title }}
              </DestinationMustSeeEntityPageLink>
            </h3>
            <p v-show="entityTypeName" class="destination-must-see-entity__type">{{ entityTypeName }}</p>
          </header>

          <div class="destination-must-see-entity__info">
            <p class="destination-must-see-entity__subheader">Address</p>
            <p>{{ entity.address }}</p>
          </div>

          <div v-if="startDate && endDate" class="destination-must-see-entity__info">
            <p class="destination-must-see-entity__subheader">{{ datesLabel }}</p>
            <p>
              <span>{{ startDate }}</span>
              <span v-show="endDate !== startDate" class="ml-1">- {{ endDate }}</span>
            </p>
          </div>

          <div
            v-if="entity.openingHourSeasons && entity.openingHourSeasons.length"
            class="destination-must-see-entity__info"
          >
            <p class="destination-must-see-entity__subheader">Hours</p>
            <div class="must-see-opening-hours">
              <div
                v-for="(ohSeason, ohSeasonIndex) in entity.openingHourSeasons"
                :key="`oh-season-${ohSeasonIndex}`"
                class="must-see-opening-hours__season"
              >
                <p v-show="ohSeason.title" class="must-see-opening-hours__season-title">{{ ohSeason.title }}:</p>
                <DataItemInfoOpeningHoursItem
                  v-for="(ohItem, ohIndex) in ohSeason.items"
                  :key="`oh-season-${ohSeasonIndex}-${ohIndex}`"
                  :item="ohItem"
                />
              </div>
            </div>
          </div>
          <div v-else-if="startTime && endTime" class="destination-must-see-entity__info">
            <p class="destination-must-see-entity__subheader">Time</p>
            <p>
              <span>{{ startTime }}</span>
              <span v-show="endTime !== startTime" class="ml-1">- {{ endTime }}</span>
            </p>
          </div>
        </div>
      </div>

      <div>
        <DestinationMustSeeEntityPageLink :entityRoute="entityRoute">
          <img v-if="mediaUrl" class="destination-must-see-entity__img" :alt="entity.title" :src="mediaUrl" />
          <div v-else class="destination-must-see-entity__without-img" />
        </DestinationMustSeeEntityPageLink>
      </div>
    </div>

    <div class="destination-must-see-entity__section destination-must-see-entity__section--actions">
      <div class="destination-must-see-entity__actions">
        <button
          v-if="!entity.is_favorite_by_current_user"
          class="destination-must-see-entity__action add-favorite"
          :disabled="favoriteChanging"
          @click="toggleFavoriteState"
        >
          <span class="add-favorite-icon" title="Add to Favourites">
            <TheHeartIcon class="add-to-favourite" />
          </span>
          <span v-if="isMobile" class="add-favorite-label">Add to Favourites</span>
        </button>
        <button
          v-else
          class="destination-must-see-entity__action add-favorite"
          :disabled="favoriteChanging"
          @click="toggleFavoriteState"
        >
          <span class="add-favorite-icon" title="In My Favourites">
            <TheHeartIcon class="in-my-favourites" filled="filled" />
          </span>
          <span v-if="isMobile" class="add-favorite-label">in My Favourites</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { EntityTypes, getEntityRoute } from '@/helpers/entityType';
import { formatDate, formatTime } from '@/helpers/dateTimeHelper';
import DestinationMustSeeEntityPageLink from '@/components/destination/DestinationMustSeeEntityPageLink.vue';
import TheHeartIcon from '@/components/icons/TheHeartIcon.vue';
import DataItemInfoOpeningHoursItem from '@/components/partials/DataItemInfoOpeningHoursItem.vue';

export default {
  name: 'DestinationMustSeeEntity',
  components: { DataItemInfoOpeningHoursItem, TheHeartIcon, DestinationMustSeeEntityPageLink },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    favoriteChanging: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    entityRoute() {
      return getEntityRoute(this.entity);
    },
    entityTypeName() {
      if (this.entity.artSpace) {
        return this.entity.artSpace.type;
      }
      if (this.entity.type) {
        return this.entity.type;
      }
      if (this.entity.category) {
        return this.entity.category;
      }
      if (this.entity.__typename === EntityTypes.historical_site) {
        return 'Historical site';
      }
      return null;
    },
    mediaUrl() {
      return this.entity.media?.[0]?.url;
    },
    startDate() {
      return formatDate(this.entity?.date?.[0]?.start_date);
    },
    endDate() {
      return formatDate(this.entity?.date?.[0]?.end_date);
    },
    datesLabel() {
      return this.startDate !== this.endDate ? 'Dates' : 'Date';
    },
    startTime() {
      return formatTime(this.entity?.date?.[0]?.start_date);
    },
    endTime() {
      return formatTime(this.entity?.date?.[0]?.end_date);
    },
  },
  methods: {
    toggleFavoriteState() {
      this.$emit('toggle-favorite', { ...this.entity });
    },
  },
};
</script>
